@import-normalize; /* bring in normalize.css styles */

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,900&display=swap");

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  width: 100vw;
  height: 100vh;
}
