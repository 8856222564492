@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,900&display=swap);
/* Document
 * ========================================================================== */ /**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */ html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
} /* Sections
 * ========================================================================== */ /**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */ h1 {
  font-size: 2em;
  margin: 0.67em 0;
} /* Grouping content
 * ========================================================================== */ /**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */ dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
} /**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
} /**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */ hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
} /**
 * Add the correct display in IE.
 */ main {
  display: block;
} /**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */ pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
} /* Text-level semantics
 * ========================================================================== */ /**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */ abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
} /**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */ b,
strong {
  font-weight: bolder;
} /**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */ code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
} /**
 * Add the correct font size in all browsers.
 */ small {
  font-size: 80%;
} /* Embedded content
 * ========================================================================== */ /**
 * Hide the overflow in IE.
 */ svg:not(:root) {
  overflow: hidden;
} /* Forms
 * ========================================================================== */ /**
 * Remove the margin on controls in Safari.
 */ button,
input,
select {
  margin: 0;
} /**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */ button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
} /**
 * Correct the inability to style buttons in iOS and Safari.
 */ button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
} /**
 * Correct the padding in Firefox.
 */ fieldset {
  padding: 0.35em 0.75em 0.625em;
} /**
 * Show the overflow in Edge 18- and IE.
 */ input {
  overflow: visible;
} /**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */ legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
} /**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */ progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
} /**
 * Remove the inheritance of text transform in Firefox.
 */ select {
  text-transform: none;
} /**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */ textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
} /**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */ [type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
} /**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */ ::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
} /**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */ ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
} /**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */ ::-webkit-search-decoration {
  -webkit-appearance: none;
} /**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */ ::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
} /**
 * Remove the inner border and padding of focus outlines in Firefox.
 */ ::-moz-focus-inner {
  border-style: none;
  padding: 0;
} /**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */ :-moz-focusring {
  outline: 1px dotted ButtonText;
} /**
 * Remove the additional :invalid styles in Firefox.
 */ :-moz-ui-invalid {
  box-shadow: none;
} /* Interactive
 * ========================================================================== */ /*
 * Add the correct display in Edge 18- and IE.
 */ details {
  display: block;
} /*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */ dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
} dialog:not([open]) {
  display: none;
} /*
 * Add the correct display in all browsers.
 */ summary {
  display: list-item;
} /* Scripting
 * ========================================================================== */ /**
 * Add the correct display in IE.
 */ template {
  display: none;
} /* User interaction
 * ========================================================================== */ /* bring in normalize.css styles */

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  width: 100vw;
  height: 100vh;
}

.home {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.body-bg {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(../../static/media/bg.4c52c41f.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media (max-width: 768px) {
  .body-bg {
    width: 75vw;
    height: 75vh;
    background-size: cover;
  }
}

header {
  display: flex;
  justify-content: center;
  padding: 3em;
}

header > div {
  height: 56px;
}

@media (min-width: 768px) {
  header > div {
    height: 64px;
  }
}

section {
  position: absolute;
  width: 100vw;
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

h1,
h4,
p {
  text-align: center;
  color: #333333;
  padding: 0 1em;
}

h1 {
  font-size: calc(40px + (70 - 40) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(0.5em + (0.5 - 0.3) * ((100vw - 300px) / (1600 - 300)));
}

p {
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  letter-spacing: -0.5px;
}

span {
  color: #6a3787;
}

h4 {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  color: #555555;
}

a {
  text-decoration: none;
  color: #6a3787;
  font-weight: 600;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 5em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

footer > p {
  font-weight: normal;
  color: #777777;
}

@media (max-width: 768px) {
  footer {
    bottom: 3em;
  }
}

