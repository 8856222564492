.home {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.body-bg {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/images/bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media (max-width: 768px) {
  .body-bg {
    width: 75vw;
    height: 75vh;
    background-size: cover;
  }
}

header {
  display: flex;
  justify-content: center;
  padding: 3em;
}

header > div {
  height: 56px;
}

@media (min-width: 768px) {
  header > div {
    height: 64px;
  }
}

section {
  position: absolute;
  width: 100vw;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

h1,
h4,
p {
  text-align: center;
  color: #333333;
  padding: 0 1em;
}

h1 {
  font-size: calc(40px + (70 - 40) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(0.5em + (0.5 - 0.3) * ((100vw - 300px) / (1600 - 300)));
}

p {
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 600;
  letter-spacing: -0.5px;
}

span {
  color: #6a3787;
}

h4 {
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;
  color: #555555;
}

a {
  text-decoration: none;
  color: #6a3787;
  font-weight: 600;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 5em;
  left: 50%;
  transform: translateX(-50%);
}

footer > p {
  font-weight: normal;
  color: #777777;
}

@media (max-width: 768px) {
  footer {
    bottom: 3em;
  }
}
